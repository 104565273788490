
body {
    font-family: 'AppleSDGothicNeo', 'Apple SD Gothic Neo';
    margin: 0;
    background-color: #f0f3f7;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    width: -webkit-fit-content;
}

@media(max-width: 1340px) {
    .blur, .grip {
        width: 1340px!important;
    }
}

.log, .log2, #issueSub, .questionList {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.log::-webkit-scrollbar, .log2::-webkit-scrollbar, #issueSub::-webkit-scrollbar, .questionList::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.material-symbols-outlined {
    font-size: 13px;
    cursor: pointer;
    color: black;
    line-height: 20px;
}

.info {
    border-radius: 5px;
    border: 0.1px solid #dddddd;
    margin: 30px 10px 0 10px !important;
    background-color: #ffffff;
    height: calc(100vh - 130px);
    min-height: 600px;
    max-height: 720px;
    box-shadow: 0.1px 0.5px 2px rgb(170, 170, 170);
    overflow: hidden;
    background-repeat : no-repeat;
    background-size : cover;
}

.head {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0px 11px 10px;
    border: 0.1px solid #dddddd;
    border-width: 0 0 0.1px 0;
    display: flex;
    align-items: end;
}

#loading {
    font-size: 12px;
    margin-left: 5px;
    color: #818487;
}

.active {
    visibility:visible;
}

.disable {
    visibility: hidden;
}

.main {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 330px 370px 640px;
    background-color: #f0f3f7;
    margin: auto;
    width: fit-content;
}

.grip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 60px;
    font-size: 25px;
    background-color: #0a093d;
}

.logo {
    display: flex;
    padding: 0 0 0 46px;
    justify-content: center;
    align-items: center;
}

.contentBox {
    width: 620px;
    height: calc(100vh - 336px);
    min-height: 390px;
    max-height: 510px;
    overflow: hidden;
    flex-wrap: wrap;
    flex-direction: column;
}

.sentimentSub {
    height: fit-content;
    width: 590px;
    margin: 0 15px;
    display: grid;
    grid-template-columns: 60px 320px 60px 60px 50px;
    column-gap: 10px;
    font-size: 13px;
}

#TimeSentiments, #NegativeSentiments {
    overflow-y: scroll;
}

.sentimentUl {
    font-size: 12px;
    color: #7a7d7e;
    position: sticky;
    top: 0;
    background-color: white;
}

.sentimentUl::after {
    display: block;
    content: '';
    height: 10px;
    width: 590px;
    position: relative;
    top: 5px;
    background-color: rgba(255, 255, 255, 0.01);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    z-index: 10;
}

.textLeft, .textRight {
    height: 100%;
    width: 100%;
}

.textLeft {
    text-align: left;
    margin-right: 10px;
    margin-top: 10px;
}

.textRight {
    text-align: right;
    margin-top: 10px;
}

.textButton {
    margin-top: 5px;
}

.pushSentiment {
    cursor: pointer;
    margin: 0 0 10px 25px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #0a093d;
    transition: 0.3s all ease;
}

.clicked {
    background-color: #0a093d;
    color: white;
}

.sub {
    width: 620px;
    height: calc(100vh - 336px);
    min-height: 390px;
    max-height: 510px;
    transition: 0.5s all ease;
}

.issue {
    display: flex;
    flex-direction: column;
}

#issueSub {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-y: scroll;
    margin-top: 10px;
    margin-left: 10px;
}

#TimeSentiments .sentimentSub .textLeft {
    overflow-x: hidden;
}

#NegativeSentiments .sentimentSub .textLeft {
    overflow-x: hidden;
}

.log {
    width: 300px;
    height: 200px;
    overflow-y: scroll !important;
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    align-items: center;
}

.manager {
    color: rgb(235, 239, 154) !important;
}

.log2 {
    width: 350px;
    height: calc(100vh - 236px);
    min-height: 490px;
    max-height: 610px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    align-items: center;
    font-size: 15px;
    margin-bottom: 10px;
    transition: all 1s ease;
}

.filter {
    display: block;
    width: 300px;
    height: 14px;
    top: -10px;
    content:"";
    position: absolute;
    background-color: rgba(121, 121, 121, 0.01);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    z-index: 10;
}

.chatting {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
}

.chat {
    display: inline;
    text-align: left;
    word-break: break-word;
    width: 280px;
}

.log2 .chat {
    width: 320px;
    padding-top: 10px;
}

.log2 .chat p {
    font-size: 13px;
}

.log2 .chat .logId {
    color: black;
    font-weight: bold;
}

.log2 .chat .manager {
    color: rgb(134, 168, 237) !important;
}

.log2 .chat .logContent {
    /*color: rgb(165, 165, 165);*/
    color: black;
}

.needComment .logContent {
    color: #e57f30 !important;
}

.interface {
    position: absolute;
    bottom: 10px;
    color: white;
}

.answer {
    transition: all 1s ease;
    background-color: white;
    font-size: 13px;
    height: 250px;
    border: 1px dashed rgb(181, 181, 181);
    border-width: 1px 0 0 0;
}

.interface2 {
    position: sticky;
    bottom: 0;
    color: white;
    background-color: white;
    z-index: 5;
    height: 55px;
}

.interface2 .input {
    width: 330px!important;
}

p {
    background-color: transparent;
    display: inline;
    line-height: 1.4;
    letter-spacing: -.28px;
    margin: 2px 0 0 0;
    padding: 0px 0 4px 0px;
    transition: all 0.5 ease;
    min-height: 18px;
    font-size: 12px;
}

.logId {
    color: color(srgb 0.7295 0.7293 0.7381);
    min-width: max-content;
    min-width: -webkit-max-content;
    padding-right: 3px;
}

.timer {
    width: 50px;
    height: 24px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 7px 0 0 0;
    background-color: transparent;
    padding: 2px 8px 0 0;
}

.input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 300px;
    color: inherit;
    position: relative;
}

.textBox {
    width: 349px;
    border: 0.1px solid #dddddd;
    border-width: 0.1px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textInput {
    position: inherit;
    width: 230px;
    height: 25px;
    padding-left: 10px;
    border: 2px solid white;
    border-radius: 15px 0 0 15px;
    border-width: 1px 0 1px 1px;
    background-color: transparent;
    color: inherit;
}

.textBox div .textInput {
    color: rgb(143,143,143);
    background-color: rgb(245,245,245);
    width: 280px;
    height: 30px;
}

.textBox div .buttonInput {
    color: rgb(134, 168, 237);
    background-color: rgb(245,245,245);
    height: 34px;
}

.textInput:focus {
    outline: none;
}

.buttonInput {
    position: inherit;
    width: 41px;
    height: 29px;
    background-color: transparent;
    border-radius: 0 15px 15px 0;
    border: 2px solid white;
    border-width: 1px 1px 1px 0;
    color: inherit;
    border-left: none;
    padding: 0px 5px 0 0;
    align-items: center;
    cursor: pointer;
}

.questionBox {
    width: 290px;
    padding: 0 5px 0 5px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease;
    max-height: 188px;
    margin: 0 0 8px 0;
    cursor: default;
    transform: translateY(0px);
}

#etc {
    margin-top: 10px;
}

#etc .questionBox {
    max-height: 580px;
}

#etc .questionBox .questionList {
    max-height: 444px;
}

.questionBox .title {
    position: relative;
    display: grid;
    grid-template-columns: 19px 242px 22px;
    border: 0.1px solid #818487;
    margin-bottom: 5px;
    padding: 2px 5px;
    z-index: 4;
    border-radius: 4px;
    transition: all 0.5s ease;
}

.Q, .text, .popup {
    display: flex;
    justify-content: start;
    align-items: stretch;
}

.Q, .text {
    cursor: default;
    margin: 4px 0 0 0;
    font-size: 13.5px;
    transition: all 0.2s ease;
}

.QQ {
    cursor: pointer;
}

.popup {
    cursor: pointer;
    transition: all 0.5s ease;
    transform: rotate(0deg);
    margin: 2px 0 0 0;
    height: 24px;
}

.questionList {
    transition: all 0.4s ease;
    max-height: 157px;
    overflow-y: scroll;
    /*overscroll-behavior: contain;*/
}

.question {
    display: grid;
    grid-template-columns: 86% 14%;
    grid-template-rows: 14px auto;
    font-size: 14px;
    margin: 2px 2px;
    padding: 2px 5px 3px 5px;
    border-radius: 5px;
    z-index: -1;
}
.id {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: 10px;
    padding-bottom: 5px;
    color: #495058;
}
.Qcontent {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    font-size: 12px;
}
.time {
    display: flex;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    font-size: 10px;
    color: #495058;
    justify-content: end;
}


.summaries {
    display: grid;
    grid-template-columns: 98px 98px 88px 200px 88px;
    align-items: start;
    justify-content: start;
    height: 100px;
    margin-left: 1px;
}

.summary {
    height: 80px;
    width: auto;
    margin: 15px 14px 10px;
}

.summaryTitle {
    color: #7a7d7e;
    font-size: 13px;
}

.summaryContent {
    font-weight: bold;
    font-size: 20px;
    margin-top: 5px;
}


.checkbar {
    width: calc(100% - 26px);
    height: 26px;
    margin: 0 13px 10px 13px;
    border-bottom: 1.5px solid #0a093d;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.radioBox, .checkBox {
    width: auto;
    height: 27px;
    display: flex;
    align-items: start;
    justify-content: start;
}

.radio {
    display: none;
}

.radio[type='radio']+label {
    display: block;
    cursor: pointer;
    text-align: center;
    margin: 0 10px 0 0 !important;
    padding: 0 3px;
    width: fit-content;
    height: 25px;
    white-space: nowrap;
    font-size: 1em;
}

.radio[type='radio']:checked+label {
    font-weight: bold;
    transition: 0.2s all ease;
}

#isResolve, #iframe {
    display: none;
}

#isResolve+label, #iframe+label {
    display: block;
    height: 16px;
    width: 33px;
    border: 1px solid #0a093d;
    border-radius: 8px;
    transition: 0.5s all ease;
}

#isResolve:checked+label, #iframe:checked+label {
    background-color: #0a093d;
}

#isResolve+label::after, #iframe+label::after {
    display: inline-block;
    content: "";
    position: relative;
    left: 1px;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background-color: #0a093d;
    transition: 0.5s all ease;
    margin: 1px 0px;
}

.windows #iframe+label::after {
    top: -1.1px;
}

#isResolve:checked+label::after, #iframe:checked+label::after {
    transform: translateX(17px);
    background-color: #ffffff;
}

.chatting .checkBox {
    position: relative;
    top: -12px;
    left: -89px;
}

.windows {
    left: -83px !important;
}

.answer {
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
}

.answerTitle {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
}

.similarAnswer .answerContent {
    color: #7a7d7e;
}

.answerButton {
    font-weight: 100;
    color: #7a7d7e;
    cursor: pointer;
}

.answerChats {
    margin-bottom: 20px;
}

.AISuggest {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AISuggest:nth-child(1) {
    margin-bottom: 5px;
}

.AIContent {
    color: rgb(136,195,226);
    width: 250px;
}

.AIButtons {
    display: flex;
    flex-direction: row;
    width: 50px;
    justify-content: space-between;
}

.AIButton {
    cursor: pointer;
}

.AIButton:nth-child(1) {
    color: #818487;
}

.AIButton:nth-child(2) {
    color: rgb(136,195,226);
}

.realAnswerId {
    display: flex;
    font-weight: bold;
    justify-content: space-between;
}

.realAnswerId div {
    font-size: 10px;
    color: #7a7d7e;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 6px;
    height: 6px;
    padding: 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #0a093d;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}

.animation-init {
    opacity: 0;
    height: 0px;
}
  
.animation-fade {
    opacity: 1;
    height: 73px;
    transition: all 0.5s ease;
}

.animation-init-tag {
    opacity: 0;
    max-height: 0px;
}
  
.animation-fade-tag {
    opacity: 1;
    max-height: 100px;
    transition: all 1.2s ease;
}

.chatEtcMenu {
    height: fit-content;
    width: 590px;
    height: 70px;
    margin: 0 15px;
    display: grid;
    column-gap: 15px;
    font-size: 13px;
    position: sticky;
    top:0;
    background-color: white;
    z-index: 10;
}

#clusters .chatEtcMenu {
    grid-template-columns: 100px 180px;
}

#funnel {
    overflow-y: scroll;
}

#funnel .chatEtcMenu {
    grid-template-columns: 55px auto;
    grid-template-rows: 25px 100px 25px 25px;
    height: 210px !important;
}

#funnel .chatEtcMenu .textLeft .chatEtcMenuContent .chatEtcMenuButton {
    margin: 0 !important;
    transform: translateY(-4px);
}

#funnel .chatEtcMenu .textLeft .sentimentTitleS {
    grid-template-columns: repeat(auto-fill, 75px) !important;
    row-gap: 10px;
}

#funnel .chatEtcMenu .textLeft .chatEtcMenuClick {
    grid-template-columns: repeat(auto-fill, 52px 13px) !important;
    margin-left: 10px;
    row-gap: 10px;
}

#funnel .sentimentSub .textLeft:first-child {
    overflow-x: hidden;
}

#clusters {
    overflow-y: auto;
}

#confirm .chatEtcMenu {
    grid-template-columns: 100px 90px 240px 120px;
    height: 110px;
}

#confirm .sentimentSub .textLeft {
    overflow-x: hidden;
}

#confirm {
    overflow-y: auto;
}

#harm .chatEtcMenu {
    grid-template-columns: 100px 120px 240px;
}

#harm {
    overflow-y: auto;
}

.chatEtcMenuTitle {
    font-size: 11.5px;
    color: #7A7D7E;
}

.chatEtcMenuContent {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    grid-gap: 10px;
    row-gap: 10px;
    font-size: 19px;
    font-weight: 600;
    color: black;
}

.chatEtcMenuButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    width: 50px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #818487;
    color: #818487;
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
}

.selected {
    display: none;
}

.selected:checked+label {
    border-color: #297DF9;
    border-width: 1.2px;
    color:#000
}

#confirm .sentimentSub {
    grid-template-columns: 50px 80px 330px 90px;
}

.hermList { 
    grid-template-columns: 50px 80px 330px 90px;
}

#harm .sentimentSub .textLeft:first-child {
    overflow-x: hidden;
}

.download {
    position: absolute;
    margin: 0 0 0 10px;
    font-size: 13px;
    color: #297DF9;
    text-decoration: underline;
    cursor: pointer;
}

.hoverTarget {
    position: relative;
    display: inline;
    top: 2px;
    z-index: 100;
}

.hoverMessage {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 9px;
    padding: 5px 10px;
    width: fit-content;
    max-width: 200px;
    min-width: 200px;
    position: absolute;
    opacity: 0;
    transition: all 0.35s ease;
    font-size: 11px;
    white-space:pre-wrap;
    text-decoration: none;
    text-align: center !important;
    font-weight: 400;
    color: rgb(122,125,126);
    word-break: keep-all;
}

.sentimentSub:nth-child(1) {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.funnelFreq {
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
}

.arrow {
    font-size: 15px;
    position: relative;
    top: -3px;
}

.chatEtcMenuText {
    grid-template-columns: 185px 50px;
    margin-top: 6px;
    margin-bottom: 7px;
}

.chatEtcMenuText div {
    font-size: 14px;
    padding: 4px 0;
    cursor: pointer;
}

.chatEtcMenuText input {
    border: 1px solid rgb(122,125,126);
    border-radius: 5px;
    padding: 4px 10px;
    height: 15px;
}

.chatEtcMenuText input::placeholder {
    size: 14px;
}

.chatEtcMenuText input:disabled {
    background-color: #F3F3F3;
}

input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: none;
}

/* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
input::-ms-clear { display: none; }

/* input type number 에서 화살표 제거 */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.chatEtcMenuContentSingle {
    grid-template-columns: auto;
}

.textDataButton {
    cursor: pointer;
}

.windows .radio[type='radio']+label {
    letter-spacing: -0.1em;
    margin: 0 7.2px 0 0 !important;
}

.windows #isResolve+label {
    position: relative;
    top: 3px;
}

.windows #isResolve+label::after {
    bottom: 1.6px;
}