.blur {
    width: 100vw;
    height: calc(100% - 90px);
    position: absolute;
    top: 60px;
    padding-bottom: 30px;
    background-color: rgb(77,77,77,0.5);
    z-index: 5;
}

@media (max-height: 721px) {
    .blur {
        height: 631px;
    }
}

.explain-dialog {
	position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
	border-radius: .4em;
    transition: all 1.2s ease;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(254, 254, 254);
    border-right-color: rgb(254, 254, 254);
    border-bottom-color: rgb(254, 254, 254);
    border-left-color: rgb(254, 254, 254);
    background-color: rgb(34, 34, 34);
    color: rgb(235, 235, 235);
    z-index: 11;
}

.explain-dialog .text {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid rgb(76, 76, 76);
    overflow: hidden;
}

.dialogTitle {
    font-weight: bold;
    font-size: 23px;
    text-align: center;
    white-space: nowrap;
}

.dialogText {
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
}

.nav-bar {
    width: calc(100% - 20px);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
    font-size: 14px;
}

.button {
    min-width: 0px;
    appearance: none;
    text-align: center;
    line-height: inherit;
    text-decoration-line: none;
    font-size: inherit;
    cursor: pointer;
    outline-width: 0px;
    transition-duration: 0.2s;
    transition-property: background, borderColor;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    -webkit-box-align: center;
    align-items: center;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    display: inline-block;
}

.back {
    margin-right: 30px;
    background-color: rgb(255, 255, 255);
    color: rgb(45, 80, 249);
    border-top-color: rgb(45, 80, 249);
}

.back:hover {
    color: rgb(45, 80, 249);
    background-color: rgba(255, 255, 255, 0.74);
    border-top-color: rgb(45, 80, 249);
}

.next {
    margin-left: 30px;
    border-top-color: rgb(45, 80, 249);
    background-color: rgb(45, 80, 249);
    color: rgb(255, 255, 255);
}

.next:hover {
    color: rgb(255, 255, 255);
    background-color: rgba(45, 80, 249, 0.64);
    border-top-color: rgb(45, 80, 249);
}

.dialog1 {
    transform: translate(-200px,-175px);
    height: 350px;
    width: 400px;
}

.dialog2 {
    transform: translate(-342px,-40px);
    height: 170px;
    width: 330px;
}

.dialog2:after {
	content: '';
	position: absolute;
	left: 0;
	top: 30%;
	width: 0;
	height: 0;
	border: 11px solid transparent;
	border-right-color: rgb(34, 34, 34);
	border-left: 0;
	margin-top: -11px;
	margin-left: -11px;
}

.dialog3 {
    transform: translate(-350px,490px);
    height: 140px;
    width: 270px;
    top: 0;
}

.dialog3:after {
	content: '';
	position: absolute;
	left: 0;
	top: 74%;
	width: 0;
	height: 0;
	border: 11px solid transparent;
	border-right-color: rgb(34, 34, 34);
	border-left: 0;
	margin-top: -11px;
	margin-left: -11px;
}

.dialog4 {
    transform: translate(30px,-200px);
    height: 180px;
    width: 340px;
}

.dialog4:after {
	content: '';
	position: absolute;
	left: 0;
	top: 20%;
	width: 0;
	height: 0;
	border: 11px solid transparent;
	border-right-color: rgb(34, 34, 34);
	border-left: 0;
	margin-top: -11px;
	margin-left: -11px;
}

.dialog5 {
    transform: translate(-313px,-200px);
    height: 180px;
    width: 340px;
}

.dialog5:after {
	content: '';
	position: absolute;
	right: 0;
	top: 20%;
	width: 0;
	height: 0;
	border: 11px solid transparent;
	border-left-color: rgb(34, 34, 34);
	border-right: 0;
	margin-top: -11px;
	margin-right: -11px;
}